/* Sticky footer styles
-------------------------------------------------- */


html {position: relative;min-height: 100%;overflow-x:hidden;}

//.row{/*border: 1px solid #ddd;*/ }
.borderg{border: 1px solid green; }
.borderr{border: 1px solid red; }

.mainbg{background-color: rgb(55,64,75)!important;}
.myfont{font-family: 'CoreSansNR65Bold', sans-serif;font-weight: 500;}
.kursiv{font-family:'Montserrat:ital',sans-serif;font-size:150%;}
.vidstup{margin-top: 80px;}
.vidstupb{margin-bottom: 80px;}
h1{margin-bottom: 0!important;}
h2,h3{margin-bottom: 1rem!important;padding-left: 12px; padding-right: 12px;}
p{margin-bottom:0;}


.container-fluid{max-width:1280px}
.top {min-height:92px;}
.pend{margin-bottom: 20px;}
.teform {text-align: right;}
.teformc {text-align: right;}
.hoverbold:hover {font-weight: bolder!important;}
@media (max-width: 768px) {
    .top {min-height:116px;}
    .teform {text-align: left;}
    .teformc {text-align: center;}
}

.p{
	margin-top:0!important;
	margin-bottom: 0.3rem!important;
}
.colorlink {color:#58dbd7!important;}
.slider-pagi__elem:before{background:#148dc1!important;}
#con1{background-color:#071850;color:#e7e7e7;}
#con2{background-color:#008ec4;color:#e7e7e7;/*margin-left:-15px;*/margin:0 -15px 0 -15px;}
#con3{background-image: linear-gradient(90deg, #b9cde5 0%, #2c4d75 100%);color:#e7e7e7;}
body { 
    background-attachment: fixed;
	margin-bottom: 0px;
	font-family: 'Montserrat';
	overflow:hidden;
	width: 100%;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

[data-type=advertising_left] {
    position: fixed;
    left: 0;
    top: 70%;
    z-index: 5;
	 cursor: pointer;
}

.call{background: #eee;color:#fefefe}
.call-animation {
    background: #fff;
    width: 135px;
    height: 135px;
    position: relative;
    margin: 0 auto;
    border-radius: 100%;
    border: solid 5px #fff;
    animation: play 2s ease infinite;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}
.call-animation img {
        width: 135px;
        height: 135px;
        border-radius: 10%;
        position: absolute;
        left: -5px;
        top: -5px;
    }
@keyframes play {
    0% {transform: scale(1);}
    15% {box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);}
    25% {box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2);}
}

.notice {
	/*font-family: 'Arial', sans-serif;*/
  margin: 1em 0 20px 15px;
 padding: 3px 1px 1px 37px;
  color: #333;
	border: 1px solid #ddd;
  border-radius: 2px;
  background: #fff;
  position: relative;
     box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.notice p {padding-top: 15px;
    font-weight: 600;color:#777
}
.notice span {padding-top:8px;
  background: inherit;
  color: inherit;
  border: none;
  display: block;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 2.9;
  position: absolute;
  left: -25px;
}
.succ {
  color: #ddd;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}
.warning {
  border: 2px solid #f0ad4e;
  color: #f0ad4e;
}
.danger {
  border: 2px solid #d9534f;
  color: #d9534f;
}
.info {
  border: 2px solid #5bc0de;
  color: #5bc0de;
}

.pole{
	display: inline-block;
	position: relative;
	width: 100%;
	height: auto;
	text-align: center;
}
.inpole{
	position: absolute; 
	left: 7%; 
	right: 6%; 
	top: 1%;
	bottom: 1%;
	z-index: 5;
	text-align: left;
	
}
.headtext {
	position: absolute; 
	left: 1px; 
	right: 1px; 
	bottom: 30px;
	text-shadow: 0 0 3px black, 0 0 1em black; 
	font-weight:bolder; 
	margin: 0 auto;
	z-index: 10;
}
.headtext > span {
	margin: 1px 10px;
	padding: 10px;
	background: rgba(55,64,75,0.8);
	color: #eee;
	border-radius: 5px;
}
.history{
	margin: 0;
	padding: 15px;
	background-image: url(/uploads/images/pronas/vst.jpg);
	background-size: cover;
}

.forma{
	display: inline-block;
	position: relative;
	width: 100%;
	text-align: right;
	vertical-align: bottom;
	background: rgba(23,162,184,0.2);
}
.nazva {
	position: absolute; 
	right: 1px;
	bottom: 30%;
	text-shadow: 0 0 3px black, 0 0 1em black; 
	font-weight:bolder; 
	margin: 0 auto;
	z-index: 10;
}
.vel {
	left: 10%;
	text-align: left;
}
.mal {
	left: 20px;
	right: 20px;
	text-align: center;
	font-size: 120%;  
}

.nazva > span {
	margin: 1px 10px;
	padding: 10px;
	background: rgba(55,64,75,0.8);
	color: #eee;
	border-radius: 2px;
}

.blogname {
	height:90px; 
  margin-bottom: 20px;
	width:100%; 
	position: relative; 
	flex-shrink:0; 
	overflow: hidden;
}


.blogname img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.blogname name{
	position: absolute; 
	left: 0; 
	right: 0; 
	bottom: -10px; 
	padding-top: 15px; 
	padding-bottom: 15px; 
	z-index:5;
	font-size: 54px;
	text-shadow: 0 0 3px black, 0 0 1em black; 
	font-weight:bolder; 
	text-align: center; 
	background-color: rgba(55,64,75,0.8);
	color: #eee; 
}

.box-tin{
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
}

/* begin accordion */
.panel-default>.panel-heading {
  background-color: rgba(55,64,75,0.9);
  border-radius: 3px;
  border-color: #e4e5e7;
  padding: 5px 10px;
  margin: 15px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.panel-default>.panel-heading a {
  color: #58dbd7!important;
  display: block;
  font-weight: 600;
}

.panel-default>.panel-heading a:hover {
  text-decoration: none;
	color: #fff !important;
	text-shadow: 0 0 0.5em #58dbd7;
}

.panel-default>.panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: block;
  font-family: 'Glyphicons Halflings'!important;
  font-style: normal;
  font-weight: 900!important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}


.panel-default>.panel-heading a[aria-expanded="true"]:after {
  content: "\2212"; 
	font-size: 180%;
  font-weight: 900;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
  content: "\002b";
	font-size: 180%;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.panel-body{
  padding: 0 10px;
}

.accordion-option {
  width: 100%;
  float: left;
  clear: both;
  margin: 15px 0;
}

.accordion-option .title {
  font-size: 20px;
  font-weight: bold;
  float: left;
  padding: 0;
  margin: 0;
}

.accordion-option .toggle-accordion {
  float: right;
  font-size: 16px;
  color: #6a6c6f;
}

.accordion-option .toggle-accordion:before {
  content: "Expand All";
}

.accordion-option .toggle-accordion.active:before {
  content: "Collapse All";
}
/* end accordion */


.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
    pointer-events: none;
}
.blockquote-footer {
    display: block;
    font-size: 90% !important;; 
    color: #6c757d;
}		
.mnone {
    margin-left: 0;
    margin-right: 0;
}		

.footer {  margin-top: 200px;
	position: absolute;
	bottom: 0 !important;
	width: 100%;
	height: 200px;
	line-height: 60px; 
	background-color: #37404b;
}
a.blog-slider__button:hover{
	color: #ddd!important
}

a .page_nav  {
    position: relative !important;
    display: block !important;
    padding: .5rem .75rem !important;
    margin-left: -1px !important;
    line-height: 1.25 !important;
    color: #007bff !important;
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important;
}

a{
color: #ddd;
    text-decoration: none;
    background-color: transparent;}
li > a:hover {color: #ddd;}
.notice p {
    margin-right: 10px;
}

legend {
    font-size: 1.1rem;
	font-weight: 600!important;
    color: #122e4c; 
}
span:empty {
    display: inline-block;
}
.common-heading {
font-family: 'CoreSansNR65Bold', sans-serif;
    margin: 20px 0 10px;
   text-transform: uppercase;
    color: #122e4c;
}
.common-heading span {
    display: inline-block;
    background: #ffffff;
    padding-right: 20px;
}


.image-hover {position:relative;display:inline-block;overflow:hidden;background:#fff;border:10px solid #fff;
box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3);-moz-box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3);-webkit-box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3)}
.image-hover a {text-decoration:none}

.img-zoom-in img 
{
transition:all .2s ease-in-out;
-webkit-transition:all .2s ease-in-out;
-moz-transition:all .2s ease-in-out;
-ms-transition:all .2s ease-in-out;
-o-transition:all .2s ease-in-out;
}

.img-zoom-in:hover img 
{
transform:scale(1.1);
-webkit-transform:scale(1.1);
-moz-transform:scale(1.1);
-ms-transform:scale(1.1);
-o-transform:scale(1.1)
}

.item-display h3 {
    background: rgb(55,64,75);
    text-align: center;
    /*color: #58dbd7;*/
    font-size: 1rem;
    padding: 10px 5px;
    margin: 0;
    font-weight: 600;
    -moz-transition: background-color 0.4s;
    -o-transition: background-color 0.4s;
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
}
.clearfix:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}
.container-card {
margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.container-card a {
  font-size: 0.75rem;
  padding: .5rem 8rem;
  color:white;
  text-transform: uppercase;
 
}

.btn-7 { background: #37404b;
  text-decoration: none;
  border: 1px solid rgb(2,98,165);
  position: relative;
  overflow: hidden;
}

//.btn-7:hover {
// 
//}

.btn-7:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(2,98,165,0.9),
    transparent
  );
  transition: all 650ms;
}

.btn-7:hover:before {
  left: 100%;
}

.wrp {
  text-align: center;
  height: 100%;
  width: 100%;
}
.wrp::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: top;
}
.icon { margin-top: -30px;
  display: inline-block;
  font-size: 25px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  color: #4099ff;
  border-radius: 100%;
  position: relative;
}
.icon::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  background: rgba(0,0,0,0.1);
  z-index: -1;
}
.icon::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  transform: scale(0);
  background: #4099ff;
  border-radius: 100%;
  z-index: -1;
}
.icon:hover {
  animation-name: bubble2;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}
.icon:hover::after {
  animation-name: bubble;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.icon-twitter {
  color: #4099ff;
}
.icon-twitter::after {
  background: #4099ff;
}
.icon-facebook {
  color: #3b5998;
}
.icon-facebook::after {
  background: #3b5998;
}
.icon-instagram {
  color: #3f729b;
}
.icon-instagram::after {
  background: #3f729b;
}
.icon-gplus {
  color: #dc4e41;
}
.icon-gplus::after {
  background: #dc4e41;
}
@-moz-keyframes bubble {
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes bubble {
  100% {
    transform: scale(1);
  }
}
@-o-keyframes bubble {
  100% {
    transform: scale(1);
  }
}
@keyframes bubble {
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes bubble2 {
  100% {
    color: #fff;
  }
}
@-webkit-keyframes bubble2 {
  100% {
    color: #fff;
  }
}
@-o-keyframes bubble2 {
  100% {
    color: #fff;
  }
}
@keyframes bubble2 {
  100% {
    color: #fff;
  }
}

.form-group{
    margin: 0px 0px 5px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 16px;
    padding: 7px;
    color: #333;
    -webkit-appearance: none!important;
}

.author {
  position: fixed;
  bottom: 15px;
  right: 15px;
  font-size: 14px;
  color: #999;}

.author a {
  color: #777;
  text-decoration: none;
}

.author a:hover {
  color: blue;
}

header.dark blockquote { color:#fff; }
header.light blockquote { color:#000; }

blockquote { 
  max-width: 1000px; 
  margin:0 auto;
  font-size: 16px; 
  border-left: 0px;
  padding:  20px ;
}

blockquote h2 { padding-right: 40px; margin: 0px; }
header.dark blockquote a {color: orange; text-decoration: underline;}
header.light blockquote a {text-decoration: underline;}
header { min-height:64px; }
header.dark { background-color:rgba(55,64,75,0.9);
 padding-bottom: 7px; padding-top: 7px}
header.light { background-color: #fff; }
nav { position: relative;  }
header.light nav { background-color:rgba(0,0,0,0.5); }

ul.main-nav { 
  list-style-type: none; 
  padding: 0px;
  font-size: 0px;
  max-width: 1072px;
  margin: 0 auto;
}

ul.main-nav > li { 
  display: inline-block;
  padding: 0; 
}

ul.main-nav > li > a { 
  display: block; 
  padding: 20px 20px;
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
}

ul.main-nav > li:hover {color: #333;  background-color: #f9f9f9; }
ul.main-nav > li:hover > a { color: #333; font-weight: 400; }
li.top-level-link:hover > a > span.bl{ color: #555; }

ul.main-nav > li ul.sub-menu-lists {
  margin: 0px;
  padding: 0px;
  list-style-type : none;
  display:block;
}

ul.main-nav > li ul.sub-menu-lists > li {
  padding: 2px 0;
}

ul.main-nav > li ul.sub-menu-lists > li > a {
  font-size: 14px;
}

.ic {
  position: fixed; 
  cursor: pointer;
  display: inline-block;
  right: 25px;
  width: 32px;
  height: 24px;
  text-align: center;
  top:0px;
  outline: none;
}

.ic.close { 
  opacity: 0; 
  font-size: 0px; 
  font-weight: 300; 
  color: #fff;
  top:8px;
  height:40px;
  display: block;
  outline: none;
}

.ic.menu { top:25px; z-index : 20; }

.ic.menu .line { 
  height: 4px; 
  width: 100%; 
  display: block; 
  margin-bottom: 6px; 
}
.ic.menu .line-last-child { margin-bottom: 0px;  }

.sub-menu-head { margin: 10px 0; }
.banners-area { margin-top: 20px; padding-top: 15px; }


@media only screen and (max-width:768px) {
  .sub-menu-head { color:orange; }
  .ic.menu { display: block; }
  header.dark .ic.menu .line { background-color: #fff; } 
  header.light .ic.menu .line { background-color: #000; }
  .ic.menu .line {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }
  .ic.menu:focus .line { background-color: #fff !important; }
  
  .ic.menu:focus .line:nth-child(1) { 
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
  }
  
  .ic.menu:focus .line:nth-child(2){ 
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); 
    margin-top: -10px;
  }
  
  .ic.menu:focus .line:nth-child(3){
    transform: translateY(15px);
    opacity: 0;
  }
  
  .ic.menu:focus{ outline: none; }
  .ic.menu:focus ~ .ic.close { opacity: 1; z-index : 21;  outline: none;  }
  .ic.menu:hover, 
  .ic.menu:focus{ opacity: 1; }
  
  nav { background-color: transparent; }
  
  ul.main-nav {
    z-index:2; 
    padding: 50px 0;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 0px;
    background-color:rgba(0,0,0,1);
    height: 100%;
    overflow: auto;
   -webkit-transition-property: background, width;
    -moz-transition-property: background, width;
    -o-transition-property: background, width;
    transition-property: background, width;
   	-webkit-transition-duration: 0.6s;
	  -moz-transition-duration: 0.6s;
	  -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
  }
  
  .ic.menu:focus ~ .main-nav { width: 300px; background-color:rgba(0,0,0,1); }
  
  ul.main-nav > * { 
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
   	-webkit-transition-duration: 0.4s;
	  -moz-transition-duration: 0.4s;
	  -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }
  .ic.menu:focus ~ .main-nav > * {opacity: 1;}
  
  ul.main-nav > li > a:after {display: none;}
  ul.main-nav > li:first-child { border-radius: 0px; }
  ul.main-nav > li {
    display: block;
    border-bottom: 1px solid #444;
  }
  
  ul.main-nav > li > a { font-weight: 600; }
  
  ul.main-nav > li ul.sub-menu-lists > li a { color: #eee; font-size: 14px; }
  .sub-menu-head { font-size: 16px;}
  ul.main-nav > li:hover { background-color: transparent; }
  ul.main-nav > li:hover > a {color: #333; text-decoration: none; font-weight: 600;}
 .ic.menu:focus ~ ul.main-nav > li > div.sub-menu-block {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
    position: relative;
    visibility: visible;
    opacity: 1.0;
  }
  
  .sub-menu-block { padding: 0 30px; }
  .banners-area { padding-bottom: 0px;  }
  .banners-area div { margin-bottom: 15px;  }
  .banners-area { border-top: 1px solid #444; }
}

@media only screen and (min-width:769px) {
  .ic.menu { display: none; }
  ul.main-nav { display: block; position: relative; }
  .sub-menu-block { padding: 15px; }
  ul.main-nav > li > div.sub-menu-block { 
  visibility: hidden;
  background-color: #f9f9f9;
  position: absolute;
  margin-top: 0px;
  width: 100%;
  color: #333;
  left: 0;
  box-sizing: border-box;
  z-index : 3;
  font-size: 16px;
  border-left: 0px solid #ccc;
  border-right: 0px solid #ccc;
  border-bottom: 1px solid #ccc;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  }
  
  ul.main-nav > li:hover > div.sub-menu-block{ 
    background-color: #f9f9f9; 
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  
  ul.main-nav > li > div.sub-menu-block > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
   	-webkit-transition-duration: 0.4s;
	  -moz-transition-duration: 0.4s;
	  -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }
  
  ul.main-nav > li:hover > div.sub-menu-block > * {
    opacity: 1;
  }
  
  .sub-menu-head { font-size: 20px;}
  
  header.dark ul.main-nav > li > a { border-right: 0px solid #bbb; }
  header.light ul.main-nav > li > a { border-right: 0px solid #666; }
  ul.main-nav > li > a:after {
    content: '';
    height: 62px;
    position: absolute;
    right:0px;
    top: 0px;
    z-index : 2;
  }
  header.dark ul.main-nav > li > a:after { background-color: #777; }
  header.light ul.main-nav > li > a:after { background-color: #999; }
  ul.main-nav > li > a.mega-menu > span { display: inline-block; vertical-align: middle; }
  ul.main-nav > li > a.mega-menu > span:after {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    content: '';
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }

  ul.main-nav > li:hover > a.mega-menu span:after{
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 5px solid #666;
  }
.banners-area { border-top: 1px solid #ccc; }
}
.bl {
	color: #dddddd; font-weight:600;
	   -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
.blw {
	color: #58dbd7; font-weight:600;
	   -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
.bl:hover, .blw:hover {color: #fff!important;font-weight:600;text-decoration:none;text-shadow: 0 0 0.5em #58dbd7;}

.soc {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  margin:0;
  padding:0;
  display:flex;
}
.soc li {
  list-style:none;
}
.soc li a {
  display:block;
  position:relative;
  width:50px;
  height:50px;
  line-height:20px;
  font-size:20px;
  text-align:center;
  text-decoration:none;
  color:#404040;
  margin: 0 30px;
  transition:.5s;
}
.soc li a span {
  position:absolute;
  transition: transform .5s;
}
.soc li a span:nth-child(1),
.soc li a span:nth-child(3){
  width:100%;
  height:3px;
  background:#404040;
}
.soc li a span:nth-child(1) {
  top:0;
  left:0;
  transform-origin: right;
}
.soc li a:hover span:nth-child(1) {
  transform: scaleX(0);
  transform-origin: left;
  transition:transform .5s;
}

.soc li a span:nth-child(3) {
  bottom:0;
  left:0;
  transform-origin: left;
}
.soc li a:hover span:nth-child(3) {
  transform: scaleX(0);
  transform-origin: right;
  transition:transform .5s;
}

.soc li a span:nth-child(2),
.soc li a span:nth-child(4){
  width:3px;
  height:100%;
  background:#404040;
}
ul li a span:nth-child(2) {
  top:0;
  left:0;
  transform:scale(0);
  transform-origin: bottom;
}
.soc li a:hover span:nth-child(2) {
  transform: scale(1);
  transform-origin: top;
  transition:transform .5s;
}
.soc li a span:nth-child(4) {
  top:0;
  right:0;
  transform:scale(0);
  transform-origin: top;
}
.soc li a:hover span:nth-child(4) {
  transform: scale(1);
  transform-origin: bottom;
  transition:transform .5s;
}

.facebook:hover {
  color: #3b5998;
}
.facebook:hover span { 
  background: #3b5998;
}
.twitter:hover {
  color: #1da1f2;
}
.twitter:hover span { 
  background: #1da1f2;
}
.instagram:hover {
  color: #c32aa3;
}
.instagram:hover span { 
  background: #c32aa3;
}
.google:hover {
  color: #dd4b39;
}
.google:hover span { 
  background: #dd4b39;
}
.soc li a .twitter {
  color: #1da1f2;
}
.soc li a:hover:nth-child(3) {
  color: #c32aa3;
}
.soc li a:hover:nth-child(4) {
  color: #dd4b39;
}

.contact-address {
    padding: 0.1em 0;
    background: #fff;
    border: 10px solid #fff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}
.gm-style .gm-style-iw {
    font-weight: 300;
    font-size: 13px;
    overflow: hidden;
    color: #333!important;
}
#gmap_canvas img{max-width:none!important;background:none!important}

.list-block {
  float: left;
  margin: 1%;
  font-size: 0;
  overflow: hidden;
}
.list-block article {
  position: relative;
  display: block;
  color: black;
  text-align: center;
}
.list-block article:after {
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transform: skew(-45deg) scaleX(0);
  -ms-transform: skew(-45deg) scaleX(0);
  transform: skew(-45deg) scaleX(0);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.list-block article:hover:after {
  -webkit-transform: skew(-45deg) scaleX(1);
  -ms-transform: skew(-45deg) scaleX(1);
  transform: skew(-45deg) scaleX(1);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.list-block article:hover figcaption h2,
.list-block article:hover figcaption p {
  -moz-transform: translate3d(0%, 0%, 0);
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.list-block article:hover figcaption h2 {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.list-block article:hover figcaption p {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
.list-block img {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  max-width: 100%;
  min-width: 100%;
  -moz-transition: opacity 0.35s ease;
  -o-transition: opacity 0.35s ease;
  -webkit-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
}
.list-block figcaption {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.list-block h2,
.list-block p {
  margin: 0;
  width: 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}
.list-block h2 {
  padding: 0 30px 10px;
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 24px;
}
.list-block p {
  padding: 0 50px;
  font-size: 14px;
  text-transform: uppercase;
}
.container-plitka{
  padding:0.1em 0;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  background: #fff;
}

@media screen and (max-width: 640px){
  .container-plitka{
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 900px){
  //.container-plitka{
  //}
}

.container-plitka .title{
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
	margin-bottom: 5px;
}

.content .content-overlay {
  background: rgba(0,0,0,0.5);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay{
  opacity: 1;
}

.content-image{
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3{
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
}

.content-details p{
  color: #fff;
  font-weight: 600;
  font-size: 0.7em;
}

.content-details a:hover{
  text-decoration: none;
  color: #fff;
}

.fadeIn-bottom{
  top: 80%;
}

.fadeIn-top{
  top: 20%;
}

.fadeIn-left{
  left: 20%;
}

.fadeIn-right{
  left: 80%;
}

 .holder {
  min-height: 100px;
}

section {
  min-height:70px;
  /*padding-top: 20px;
    margin-bottom: -2px;*/
}


.parallax {
  position: relative;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.parallax-1 {  opacity: 0.7;
  min-height: 150px;
  background-image: url(/img/aisplash.webp);}
.news-post {
	font-family: 'Open Sans', sans-serif;
}
hr {
  background:#dedede;
  border:0;
  height:1px;
}
.conteudo {
  padding:5px;
  background: #fff;
 
}	
											 
.conteudo h1 {
  margin:0 0 15px;
  padding:0;
  font-weight: normal;
  color: #666;
}

.conteudo p:last-child {
  margin: 0;
}

.conteudo .continue-lendo {
  color:#000;
  font-weight: 700; 
  text-decoration: none;
  transition: all 0.5s ease;
}

.conteudo .continue-lendo:hover {
  margin-left:10px;
}

.post-info {
   margin-left: auto;
	margin-right: auto;
  font-size: 12px;
  text-transform: uppercase;
}
@media screen and (max-width: 460px) {
 

  .post-info {
  display:none;
}
  
  .conteudo {
  margin:5px auto;
  }  
}	

.user_card { margin-top:50px;
			min-height: 300px;
			width: 350px;			
			margin-bottom: auto;		
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 10px;
		 border: solid #ddd 1px;
			border-radius: 5px;
box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);

}
.brand_logo_container {
			position: absolute;
			height: 150px;
			width: 150px;
			top: -75px;
			border-radius: 50%;
			background: #2d89da;
			padding: 10px;
			text-align:center;
}
.brand_logo {
			height: 150px;
			width: 150px;
			border-radius: 50%;
			border: 2px solid white;
}
.form_container {
			margin-top:15%;
			font-size: smaller;
}
	
.link{color: #333;}											 
.anim-show{	
	color:#fff;
	opacity:0; 
	transition: 1s;
	animation: show 3s 1;
       animation-fill-mode: forwards; 
       animation-delay: 1s; 
}
@keyframes show{
 0%{ opacity:0; }
 100% { opacity:1; }
}															 

img {
  height: auto;
	width: 100%;
}
.btn-n {
  background-color: white;
  border: 1px solid #cccccc;
  color: #696969;
  padding: 0.5rem;
}
.btn-g {
  background-color: rgb(55,64,75);
  font-weight: bold;
  border: 3px solid transparent;
  color: #58dbd7;
  padding: 0.2rem 0.5rem;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
	transition-duration: 0.5s;
}
.btn-g:hover {
  color: #fff;
  text-decoration: none;  
	text-shadow: 0 0 0.5em #58dbd7;
  /*box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);*/
}

.btn--block {
  display: block;
  width: 100%;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.cards__item {
  display: flex;
  padding: 0.2rem;
}
@media (min-width: 40rem) {
  .cards__item {
    width: 50%;
  }
}
@media (min-width: 56rem) {
  .cards__item {
    width: 25%;
  }
}
.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.card:hover .card__image {
  -webkit-filter: contrast(100%);
          filter: contrast(100%);
}
.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}
.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  -webkit-filter: contrast(70%);
          filter: contrast(70%);
  overflow: hidden;
  position: relative;
  transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91), -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
}
.card__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (min-width: 40rem) {
  .card__image::before {
    padding-top: 66.6%;
  }
}

.card__title {
  color: #696969;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.card__text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

#app_kolona,#app {
    background: #fff;
    color: #fff;
    opacity: 0;
    transition: 1s;
    animation: show 3s 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}
ul.main-nav > li > a:after{
width: 1px;
 background: #fff;
    color: #fff;
    opacity: 0;
    transition: 1s;
    animation: show 3s 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;	
}
[data-type=advertising_left]	{
    opacity: 0;
    transition: 1s;
    animation: show 3s 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;		
}

.alert>.start-icon {
    margin-right: 0;
    min-width: 20px;
    text-align: center;
}

.alert>.start-icon {
    margin-right: 5px;
}

.alert-simple.alert-info{
  border: 1px solid rgba(6, 44, 241, 0.46)!important;
    background-color: rgba(7, 73, 149, 0.12156862745098039)!important;  
    color: #0396ff;  
  transition:0.5s;
  cursor:pointer;
}

.alert-info:hover{
  background-color: rgba(7, 73, 149, 0.35)!important;
  transition:0.5s!important;
}

.blue-cross{
  font-size: 18px;
    color: #0bd2ff;
    text-shadow: none;
}


.fa-times
{
-webkit-animation: blink-1 2s infinite both;
	        animation: blink-1 2s infinite both;
}

.mini-container {width:90%; margin:0 auto;}
.clear {clear:both;}
.g50 {height:auto;float:left;}
.g50 {width:50%;}
.padding {padding: 15px;}
.left {float:left; z-index:50; position:relative; margin-top:-30px; margin-left:-40px;}
.btn-left {width:100%; height:auto; text-align:right; position:relative; background:url(/img/line.png) repeat-x center;}
.service-btn {background:#148dc1; padding:10px 20px;border:rgba(0, 74, 117, 0.4) 3px solid; -webkit-border-radius: 7px;
-moz-border-radius: 7px; border-radius: 7px; position:relative; width:80%; position:relative; max-height:50px; line-height:20px; color: #fff}

.service-btn a:hover {color:#ddd;}

.service-btn img {background:#004a75; padding:3px; border:rgba(235, 239, 241, 0.93) 3px solid; -webkit-border-radius: 50%;
-moz-border-radius: 50%; border-radius: 50%; width:23%;}

.float-left {float:left;}
.dot-left {border:rgba(0, 74, 117, 0.4) 3px solid; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%; 
width:25px; height:25px; padding:5px; position:absolute; top: 30%; right:0; background:#148dc1;}
.right {float:right; z-index:50; position:relative; margin-top:-30px; margin-right:-38px;}
.btn-right {width:100%; height:auto; text-align:left; position:relative; background:url(/img/line.png) repeat-x center; z-index: 100}
.btn-left {width:100%; height:auto; text-align:right; position:relative; background:url(/img/line.png) repeat-x center; z-index: 100}
.float-right {float:right;}
.dot-right {border:rgba(0, 74, 117, 0.4) 3px solid; -webkit-border-radius: 50%; -moz-border-radius: 50%;
 border-radius: 50%; width:25px; height:25px; padding:5px; position:absolute; top: 30%; left:0; background:#148dc1;}
.spacer {height:50px; padding:5px;}
@media screen and (max-width:400px) {
.mini-container{
	display:none;
}			
.vstavka_small{
	display: block;
}

}

@media (min-width: 576px) {
.mini-container{display:none;}			
.vstavka_small{display: block;}
}

@media (min-width: 768px) {
.mini-container{display:none;}		
.vstavka_small{display: block;}		
}

@media (min-width: 992px) { 
.mini-container{display:none;}				
.vstavka_small{display: block;}
}

@media (min-width: 1200px) { 
.mini-container{display: block;}		
.vstavka_small{display: none;}		
}

.liststyles li {
    padding: 10px;
    border: #E4E4E4 1px solid;
    margin: 4px 0;
    background: #EBEBEB;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    margin-left: auto;
}
.padding {
    padding: 1px;
}
.liststyles ul {
    list-style-type: none;
}
.about-list {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.about__item {
  display: flex;
  flex-grow: 1;
  flex-basis:400px;
  width: 33%;
  padding: 0px;
}

figure {
  width: 100%;
  margin: 0 0 2rem;
  background-color: #fff;
  //box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 600px){
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image) {
      box-shadow: 0px 2px 3px #0000004d;
      padding: 4px;
  }
}

figure p {
  margin: 0.5em;
}
figure img {
  width: 100%;
}
figure figcaption { font-size:14px;
  padding:10px;
}

figcaption{
	background:rgba(55,64,75,0.9);
	/*color:#58dbd7;*/
}

figcaption h2{
text-align: center;
font-size: 1.3em;	
margin: 0.2em;
}
.modal-body {
	border: none;
}
.modal-header {
    border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	background:  rgba(55,64,75,0.9);
	color: #58dbd7;
}
.modal-title{
	color:#58dbd7;
}
.modal-content{
	border-radius: 3px;
	border: none;
}
.modal-footer {
    border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	background:  rgba(55,64,75,0.9);
}

.dropdown-item.active, .dropdown-item:active { 
  text-decoration: none;
  background-color: #e9ecef !important;
}
.blog-text {font-family: inherit!important;}