.navbar-light .navbar-nav .nav-link{
    font-weight: 600;
    transition-duration: .5s;
    color: #58dbd7;
}
.navbar-light 
.navbar-nav 
.active>.nav-link, 
.navbar-light 
.navbar-nav 
.nav-link.active, 
.navbar-light 
.navbar-nav 
.nav-link.show, 
.navbar-light 
.navbar-nav 
.show>.nav-link{
    color: #fff;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
}