
@import "base/_fonts";



//@import "../fonts/dashicons/dashicons.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap";
@import "../../node_modules/fontawesome-4.7/css/font-awesome.min";
@import "../../node_modules/alertifyjs/build/css/alertify";
@import "../../node_modules/animate.css/animate.min";
@import "base/_normalize";
@import "base/_alertify";
@import "base/_style_slider";
@import "base/_navbar";
@import "base/_all";
@import "base/_fromsite";
@import "base/_blog";
@import "base/_vidstup.scss";
@import "base/_news.scss";


body{
    background-color: white;
}


.margin_left10{
    margin-left: 10px !important;
}


.wp-block-columns{
    margin-right: 10px;
    margin-left: 10px;
}

.category-blog .container-fluid{
    min-height: 500px;
}
.nopadding{padding:0px;}
h2, h3{padding:0px;}
h1{
    margin-left: 15px;
}

@media only screen and (max-width: 768px){
    h1{
        margin-left: 0px;
    }
}

.row{margin-left: 0;}

.readpic {
    width: 25px;
    opacity: 0;
}
.readpica {
    text-align: center;
    display: none;
    cursor: default;
}

.wp-block-columns{
    margin-right: 10px;
    margin-left: 10px;
}
.figure{
    box-shadow: 0px 2px 3px #0000004d;
}
.wp-block-image{
    box-shadow: 0px 2px 3px #0000004d;
}

.wp-block-image img{
    height: auto;
    width: auto;
}
.row{
    width: 100%;
}

/*
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
    background-color: #37404b !important;
    color: white !important;
    padding: 5px !important;
}
*/

.ios-prompt {
    background-color: #fcfcfc;
    border: 1px solid #666;
    display: none;
    padding: 0.8rem 1rem 0 0.5rem;
    text-decoration: none;
    font-size: 16px;
    color: #555;

    position: absolute;
    margin: 0 auto;
    left: 1rem;
    right: 1rem;
    bottom: 0;
  }  

#readmore{
    padding-left: 15px;
    padding-right: 15px;
    strong{
        font-weight: normal !important;
    }
}
.readpica{
    text-align: center;
    display: block;
}
.readpic{
    width: 25px;
}

.callback_button{
    border-radius: 0px 10px 10px 0px;
    height: 143px; 
    width: 35px;
    -webkit-box-shadow: 4px 0px 8px 0px #00e3d95c;
    -moz-box-shadow: 4px 0px 8px 0px #00e3d95c;
    box-shadow: 4px 0px 8px 0px #00e3d95c;
}
.bigbutton{
    background-color: #4b535d;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 120%;
    color: #eee;
    text-decoration: underline;
    padding: 0.7em;
}
.bigbutton:hover{
    color: #eee;
}

.bigbutton.inverse{
    background-color: #fff;
    color: #4b535d;
}

#searchform{
    padding: 1em;
    #s{
       width: 100%; 
    };
}

.flink{
    font-weight: 700;
}

.nogap{
	display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-items: center;
    justify-content: flex-start;
    gap: unset !important;
}

.searh-modal{
    .close{
        position: absolute;
        right: 0px;
        top: -4px;
        color: red;
    }
}

.bl.fab.fa-facebook-f:hover{
    color: #0d53d3 !important;
}
.bl.fab.fa-youtube:hover{
    color: #f00 !important;
} 
.bl.fab.fa-instagram:hover{
    color: #ff419b !important; 
}  
.bl.fab.fa-viber:hover{
    color: #640c91 !important; 
}  
.bl.fab.fa-telegram-plane:hover{
    color: #62a1ff !important; 
}  





.list-unstyled{
    .active a{
        color: #58dbd7;
    } 
    a:hover{
        color: #58dbd7;
        text-decoration:none;
    }
}


#tooltip {
    background: #333;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    display: none;
}
      


#tooltip[data-show] {
  display: block;
}
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}


.alertify.ajs-resizable:not(.ajs-maximized) .ajs-dialog {
    min-width: 80%;
    min-height: 80%;
}
.ajs-dialog {
    background: #FFF;
    border: 2px solid rgba(0,0,0,.7);
    border-radius: 8px;
    box-shadow: 0 3px 3px rgba(0,0,0,.3);
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
} 
.alertify .ajs-header {
    border-radius: 8px;
}
.alertify .ajs-footer {
    border-radius: 8px;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: center;
}

.ajs-button{
    background-color: #5CB811;
    border: 1px solid #3B7808;
    border-radius: 5px;
    color: #000;
}
.ajs-button:hover,
.ajs-button:focus {
    background-color: #5CB811;
    border: 1px solid #3B7808;
    border-radius: 5px;
    color: #fff;
}

.video{
    cursor: pointer;
    max-height: 168px;
    object-fit: cover; 
    width:300px
}