
.vidstup10{
    margin-top: 10px;
}
.vidstup20{
    margin-top: 20px;
}
.vidstup30{
    margin-top: 30px;
}
.vidstup40{
    margin-top: 40px;
}
.vidstup50{
    margin-top: 50px;
}
.vidstup60{
    margin-top: 60px;
}
.vidstup70{
    margin-top: 70px;
}
.vidstup80{
    margin-top: 80px;
}
.vidstup90{
    margin-top: 90px;
}
.vidstup100{
    margin-top: 100px;
}





@media (max-width: 768px){

    .vidstup10{
        margin-top: 0px !important;
    }
    .vidstup20{
        margin-top: 0px !important;
    }
    .vidstup30{
        margin-top: 0px !important;
    }
    .vidstup40{
        margin-top: 0px !important;
    }
    .vidstup50{
        margin-top: 0px !important;
    }
    .vidstup60{
        margin-top: 0px !important;
    }
    .vidstup70{
        margin-top: 0px !important;
    }
    .vidstup80{
        margin-top: 0px !important;
    }
    .vidstup90{
        margin-top: 0px !important;
    }
    .vidstup100{
        margin-top: 0px !important;
    }

    //for mobile
    .m-vidstup10{
        margin-top: 10px !important;
    }
    .m-vidstup20{
        margin-top: 20px !important;
    }
    .m-vidstup30{
        margin-top: 30px !important;
    }
    .m-vidstup40{
        margin-top: 40px !important;
    }
    .m-vidstup50{
        margin-top: 50px !important;
    }
    .m-vidstup60{
        margin-top: 60px !important;
    }
    .m-vidstup70{
        margin-top: 70px !important;
    }
    .m-vidstup80{
        margin-top: 80px !important;
    }
    .m-vidstup90{
        margin-top: 90px !important;
    }
    .m-vidstup100{
        margin-top: 100px !important;
    }
    
}
