/* ------------- General ------------- */


.csslider1 {
  display: inline-block;
  position: relative;
  max-width: 1900px;
  
  

  width: 100%;
}
.csslider1,
.csslider1 *,
.csslider1 *:after,
.csslider1 *:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.csslider1 > .cs_anchor {
  display: none;
}
.csslider1 > ul {
  position: relative;
  z-index: 1;
  font-size: 0;
  line-height: 0;
  margin: 0 auto;
  padding: 0;
  
  overflow: hidden;
  white-space: nowrap;
}
.csslider1 > ul > .cs_skeleton {
  width: 100%;
  visibility: hidden;
  font-size: 0px;
  line-height: 0;
}

.csslider1 > ul > .slide.img img {
  width: 100%;
}



.csslider1 > ul > .slide.img a {
  font-size: 0pt;
  color: transparent;
}
.csslider1 > ul > .slide {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 15px;
  font-size: initial;
  line-height: normal;
  white-space: normal;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.csslider1 .cs_engine{
  position: absolute;
  top: -9999px;
  left: -9999px;
  font-size: 0pt;
  opacity: 0;
  filter: alpha(opacity=0);
}

.csslider1 > ul > li.img,
.csslider1 > .cs_arrowprev,
.csslider1 > .cs_arrownext,
.csslider1 > .cs_bullets,
.csslider1 > .cs_play_pause {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ------------- /General Styles ------------- */

/* ------------- General Description ------------- */
.csslider1 > .cs_description {
  z-index: 3;
}
.csslider1 > .cs_description a,
.csslider1 > .cs_description a:visited,
.csslider1 > .cs_description a:active {
  color: inherit;
}
.csslider1 > .cs_description a:hover {
  text-decoration: none;
}
.csslider1 > .cs_description > label {
  position: absolute;
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
  max-width: 50%;
  left: 0;
}
.csslider1 > .cs_description > label > span {
  vertical-align: top;
}
.csslider1 > .cs_description > label span {
  display: inline-block;
}
/* ------------- /General Description ------------- */

/* ------------- Effect ------------- */

.csslider1 > #cs_slide1_0:checked ~ ul > .slide.num0,
.csslider1 > #cs_pause1_0:checked ~ ul > .slide.num0,
.csslider1 > #cs_slide1_1:checked ~ ul > .slide.num1,
.csslider1 > #cs_pause1_1:checked ~ ul > .slide.num1,
.csslider1 > #cs_slide1_2:checked ~ ul > .slide.num2,
.csslider1 > #cs_pause1_2:checked ~ ul > .slide.num2,
.csslider1 > #cs_slide1_3:checked ~ ul > .slide.num3,
.csslider1 > #cs_pause1_3:checked ~ ul > .slide.num3,
.csslider1 > #cs_slide1_4:checked ~ ul > .slide.num4,
.csslider1 > #cs_pause1_4:checked ~ ul > .slide.num4 {
  opacity: 1;
  z-index: 2;
}

.csslider1 > ul > .slide {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  
  -webkit-transition: opacity 2000ms ease;
  transition: opacity 2000ms ease;

  
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}


@-webkit-keyframes fade1 {
  0%, 25%, 100%  { opacity: 0; }
  5%, 20%  { opacity: 1; }
  0%, 19.999%  { z-index: 2; }
  20.001%, 100%  { z-index: 1; }
}
@keyframes fade1 {
  0%, 25%, 100%  { opacity: 0; }
  5%, 20%  { opacity: 1; }
  0%, 19.999%  { z-index: 2; }
  20.001%, 100%  { z-index: 1; }
}

.csslider1 > #cs_play1:checked ~ ul > .slide.num0 {
  -webkit-animation: fade1 40000ms infinite -2000ms;
  animation: fade1 40000ms infinite -2000ms;
}
.csslider1 > #cs_play1:checked ~ ul > .slide.num1 {
  -webkit-animation: fade1 40000ms infinite 6000ms;
  animation: fade1 40000ms infinite 6000ms;
}
.csslider1 > #cs_play1:checked ~ ul > .slide.num2 {
  -webkit-animation: fade1 40000ms infinite 14000ms;
  animation: fade1 40000ms infinite 14000ms;
}
.csslider1 > #cs_play1:checked ~ ul > .slide.num3 {
  -webkit-animation: fade1 40000ms infinite 22000ms;
  animation: fade1 40000ms infinite 22000ms;
}
.csslider1 > #cs_play1:checked ~ ul > .slide.num4 {
  -webkit-animation: fade1 40000ms infinite 30000ms;
  animation: fade1 40000ms infinite 30000ms;
}



.csslider1 > #cs_play1:checked ~ ul > .slide,
.csslider1 > .pause:checked ~ ul > .slide {
  -webkit-transition: none;
  transition: none;
}


/* ------------- /Effect ------------- */

/* ------------- Template Description ------------- */


.csslider1 > .cs_description > label {
  /*font-family: "Lato",sans-serif; */
  line-height: normal;
  top: auto;
  z-index: 3;
  text-shadow: 0 1px 0 #34495E;
  max-width: 75%;
  color: #fff;

  bottom: 50px;
  top: auto;
  left: 0px;
  /*padding-left:  50px;*/
}
.csslider1 > .cs_description > label .cs_title {
  margin-bottom: 10px;
  font-size: 65px;
  font-weight: 500;
  background: rgba(55,64,75,0.8);
  padding: 10px 20px 10px 70px;
}
.csslider1 > .cs_description > label .cs_descr {
  background: #e1e1e1;
  padding: 5px 20px;
  text-align: left;
  font-size: 12px;
  
}




.csslider1 > #cs_slide1_0:checked ~ .cs_description > .num0 > .cs_title,
.csslider1 > #cs_slide1_0:checked ~ .cs_description > .num0 > .cs_descr,
.csslider1 > #cs_pause1_0:checked ~ .cs_description > .num0 > .cs_title,
.csslider1 > #cs_pause1_0:checked ~ .cs_description > .num0 > .cs_descr,
.csslider1 > #cs_slide1_1:checked ~ .cs_description > .num1 > .cs_title,
.csslider1 > #cs_slide1_1:checked ~ .cs_description > .num1 > .cs_descr,
.csslider1 > #cs_pause1_1:checked ~ .cs_description > .num1 > .cs_title,
.csslider1 > #cs_pause1_1:checked ~ .cs_description > .num1 > .cs_descr,
.csslider1 > #cs_slide1_2:checked ~ .cs_description > .num2 > .cs_title,
.csslider1 > #cs_slide1_2:checked ~ .cs_description > .num2 > .cs_descr,
.csslider1 > #cs_pause1_2:checked ~ .cs_description > .num2 > .cs_title,
.csslider1 > #cs_pause1_2:checked ~ .cs_description > .num2 > .cs_descr,
.csslider1 > #cs_slide1_3:checked ~ .cs_description > .num3 > .cs_title,
.csslider1 > #cs_slide1_3:checked ~ .cs_description > .num3 > .cs_descr,
.csslider1 > #cs_pause1_3:checked ~ .cs_description > .num3 > .cs_title,
.csslider1 > #cs_pause1_3:checked ~ .cs_description > .num3 > .cs_descr,
.csslider1 > #cs_slide1_4:checked ~ .cs_description > .num4 > .cs_title,
.csslider1 > #cs_slide1_4:checked ~ .cs_description > .num4 > .cs_descr,
.csslider1 > #cs_pause1_4:checked ~ .cs_description > .num4 > .cs_title,
.csslider1 > #cs_pause1_4:checked ~ .cs_description > .num4 > .cs_descr {
  opacity: 1;
  visibility: visible;
}

.csslider1 > .cs_description > label > .cs_title {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  -webkit-transition: opacity .4s, visibility .4s;
  transition: opacity .4s, visibility .4s;
}
.csslider1 > .cs_description > label > .cs_descr {
  font-size: 0.8em;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: opacity .4s, visibility .4s;
  transition: opacity .4s, visibility .4s;
}




@-webkit-keyframes cs_title1 {
  1.25%, 20%  { opacity: 1; z-index: 2; visibility: visible;}
  21.25%  { opacity: 0; z-index: 2; visibility: hidden;}
  21.26%, 100%  { z-index: 0; }
}
@keyframes cs_title1 {
  1.25%, 20%  { opacity: 1; z-index: 2; visibility: visible;}
  21.25%  { opacity: 0; z-index: 2; visibility: hidden;}
  21.26%, 100%  { z-index: 0; }
}


.csslider1 > #cs_play1:checked ~ .cs_description > .num0  > .cs_title {
  -webkit-animation: cs_title1 40000ms infinite -1400ms ;
  animation: cs_title1 40000ms infinite -1400ms ;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num1  > .cs_title {
  -webkit-animation: cs_title1 40000ms infinite 6600ms ;
  animation: cs_title1 40000ms infinite 6600ms ;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num2  > .cs_title {
  -webkit-animation: cs_title1 40000ms infinite 14600ms ease;
  animation: cs_title1 40000ms infinite 14600ms ease;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num3  > .cs_title {
  -webkit-animation: cs_title1 40000ms infinite 22600ms ease;
  animation: cs_title1 40000ms infinite 22600ms ease;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num4  > .cs_title {
  -webkit-animation: cs_title1 40000ms infinite 30600ms ease;
  animation: cs_title1 40000ms infinite 30600ms ease;
}


.csslider1 > #cs_play1:checked ~ .cs_description > .num0  > .cs_descr {
  -webkit-animation: cs_title1 40000ms infinite -1400ms ease;
  animation: cs_title1 40000ms infinite -1400ms ease;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num1  > .cs_descr {
  -webkit-animation: cs_title1 40000ms infinite 6600ms ease;
  animation: cs_title1 40000ms infinite 6600ms ease;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num2  > .cs_descr {
  -webkit-animation: cs_title1 40000ms infinite 14600ms ease;
  animation: cs_title1 40000ms infinite 14600ms ease;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num3  > .cs_descr {
  -webkit-animation: cs_title1 40000ms infinite 22600ms ease;
  animation: cs_title1 40000ms infinite 22600ms ease;
}
.csslider1 > #cs_play1:checked ~ .cs_description > .num4  > .cs_descr {
  -webkit-animation: cs_title1 40000ms infinite 30600ms ease;
  animation: cs_title1 40000ms infinite 30600ms ease;
}



.csslider1 > #cs_play1:checked ~ .cs_description > label > .cs_title,
.csslider1 > #cs_play1:checked ~ .cs_description > label > .cs_descr,
.csslider1 > .pause:checked ~ .cs_description > label > .cs_title,
.csslider1 > .pause:checked ~ .cs_description > label > .cs_descr {
  -webkit-transition: none;
  transition: none;
}




/* ------------- /Template Description ------------- */
/* hide description & bullets on mobile devices */
@media
only screen and (max-width: 480px),
only screen and (-webkit-max-device-pixel-ratio: 2)      and (max-width: 480px),
only screen and (   max--moz-device-pixel-ratio: 2)      and (max-width: 480px),
only screen and (     -o-max-device-pixel-ratio: 2/1)    and (max-width: 480px),
only screen and (        max-device-pixel-ratio: 2)      and (max-width: 480px),
only screen and (                max-resolution: 192dpi) and (max-width: 480px),
only screen and (                max-resolution: 2dppx)  and (max-width: 480px) { 
  .csslider1 > .cs_description,
  .csslider1 > .cs_bullets {
    display: none;
  }
}