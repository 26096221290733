
* {
    box-sizing: border-box;
  }
  
  
  .news{min-height: 1vh;
    margin-left: 4%;
    display: flex;}
    
    .blog-slider {
      width: 95%;
      position: relative;
      max-width: 1000px;
      margin: auto;
      background: #fff;
      box-shadow: 2px 3px 3px 2px rgba(0, 0, 0, 0.3);	
      padding: 25px;
      border-radius: 5px;
      height: 350px;
      transition: all .3s;
  }
    
    
    
  
  @media screen and (max-width: 992px) {
    .blog-slider {
      max-width: 680px;
      height: 400px;
    }
  }
  @media screen and (max-width: 768px) {
    .blog-slider {
      min-height: 500px;
      height: auto;
      margin: 0px auto;
    }
  }
  @media screen and (max-height: 500px) and (min-width: 992px) {
    .blog-slider {
      height: 350px;
    }
  }
  .blog-slider__item {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    .blog-slider__item {
      flex-direction: column;
    }
  }
  .blog-slider__item.swiper-slide-active .blog-slider__img img {
    opacity: 1;
    transition-delay: .3s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > * {
    opacity: 1;
    transform: none;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
    transition-delay: 0.3s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
    transition-delay: 0.4s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
    transition-delay: 0.5s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
    transition-delay: 0.6s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
    transition-delay: 0.7s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
    transition-delay: 0.8s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
    transition-delay: 0.9s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
    transition-delay: 1s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
    transition-delay: 1.1s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
    transition-delay: 1.2s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
    transition-delay: 1.3s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
    transition-delay: 1.4s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
    transition-delay: 1.5s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
    transition-delay: 1.6s;
  }
  .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
    transition-delay: 1.7s;
  }
  .blog-slider__img {
    width: 300px;
    flex-shrink: 0;
    height: 300px;
    background-image: linear-gradient(147deg, #1e90ff 0%, #1e90ff 54%);
   
    border-radius: 3px;
    transform: translateX(-80px);
    overflow: hidden;
  }
  .blog-slider__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(147deg, #0278cc 0%, #0264aa 74%);
    border-radius: 3px;
    opacity: 0.4;
  }
  .blog-slider__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 0;
    border-radius: 3px;
    transition: all .3s;
  }
  @media screen and (max-width: 768px) {
    .blog-slider__img {
      transform: translateY(-50%);
      width: 90%;
      margin-top: 200px; 
    }
  }
  @media screen and (max-width: 576px) {
    .blog-slider__img {
      width: 95%;
     margin-top: 200px
    }
  }
  @media screen and (max-height: 500px) and (min-width: 992px) {
    .blog-slider__img {
      height: 270px;margin-top: 200px;
    }
  }
  .blog-slider__content {
    padding-right: 25px;
  }
  @media screen and (max-width: 768px) {
    .blog-slider__content {
      margin-top: -80px;
      text-align: center;
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 576px) {
    .blog-slider__content {
      padding: 0;
    }
  }
  .blog-slider__content > * {
    opacity: 0;
    transform: translateY(25px);
    transition: all .4s;
  }
  .blog-slider__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
  }
  .blog-slider__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px;
  }
  .blog-slider__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
  }
  .blog-slider__button {
      display: inline-flex;
      background-image: linear-gradient(147deg, #0278cc 0%, #37404b 54%);
      padding: 5px 35px;
      border-radius: 2px;
      color: #fff;
      text-decoration: none;
      font-weight: 500;
      justify-content: center;
      text-align: center;
      letter-spacing: 1px;
  }
  @media screen and (max-width: 576px) {
    .blog-slider__button {
      width: 100%;
    }
  }
  .blog-slider .swiper-container-horizontal > .swiper-pagination-bullets, .blog-slider .swiper-pagination-custom, .blog-slider .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  .blog-slider__pagination {
    position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px !important;
    text-align: center;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    transform: translateY(-50%);
  }



.posts-navigation{
    width: 100%;
}
.nav-links{
    display: flex;
    justify-content: space-around;
}
.nav-previous {
  background-color: #37404b;
  padding: 5px;
}

.nav-previous a{
    background-color: #37404b;
    padding: 5px;
}
.nav-previous a:hover{
    color:#fff;
}
.nav-next a:hover{
  color:#fff;
}
.nav-next a{
    background-color: #37404b;
    padding: 5px;
}
.nav-next{
    background-color: #37404b;
    padding: 5px;
    float: right;
}



  @media screen and (max-width: 768px) {
    .blog-slider__pagination {
      transform: translateX(-50%);
      left: 50% !important;
      top: 205px;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 8px 0;
  }
  @media screen and (max-width: 768px) {
    .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 5px;
    }
  }
  .blog-slider__pagination .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 10px;
    background: #062744;
    opacity: 0.2;
    transition: all .3s;
  }
  .blog-slider__pagination .swiper-pagination-bullet-active {
    opacity: 1;
    background: #0264aa;
    height: 30px;
    
  }
  @media screen and (max-width: 768px) {
    .blog-slider__pagination .swiper-pagination-bullet-active {
      height: 11px;
      width: 30px;
    } 
    
  }
  