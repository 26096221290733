
.wp-block-latest-posts__featured-image img {
    height: auto;
    width: auto;
    max-width: 100%;
    min-height: 305px;
}

.wp-block-latest-posts__featured-image a{
    background: 0 0 !important;
    padding: 0px !important;
    margin: 0 !important;
    display: block !important;
    width: 100% !important;
}
.wp-block-latest-posts__list li a:last-child{
    background: rgb(55,64,75);
    text-align: center;
    font-size: 1rem;
    padding: 10px 0px;
    margin: 0;
    font-weight: 600;
    -moz-transition: background-color 0.4s;
    -o-transition: background-color 0.4s;
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
    display: block;
    width: 100%;
}
